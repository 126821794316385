<template>
  <div class="banner">
    <a :href="block.data.link">
      <img :src="block.data.src" :alt="block.data.alt" class="img-responsive" />
    </a>
  </div>
</template>

<style>
.banner {
  display: flex;
  justify-content: center;
}
</style>

<script>
export default {
  name: 'BlockBanner',
  props: {
    block: Object,
  },
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
